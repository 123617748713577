import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Card } from '../../app/entities'

export interface CardState {
  value: Card | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CardState = {
  value: null,
  status: 'idle',
};


export function fetchCard(card : Card | null) {
  return new Promise<{ data: Card | null }>((resolve) =>
    setTimeout(() => resolve({ data: card }), 750)
  );
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const setCardThunk = createAsyncThunk(
  'show/setCard',
  async (card: Card|null) => {
    const response = await fetchCard( card );
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const showSlice = createSlice({
  name: 'show',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCard: (state, action: PayloadAction<Card | null>) => {
      state.value = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(setCardThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setCardThunk.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      });
  },
});

export const { setCard } = showSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCard = (state: RootState) => state.show.value;


export default showSlice.reducer;
